import React, { useRef, useEffect } from 'react';
import '../styles/header.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

//images
import logo from '../images/logo.svg'

const Header = ({configRef, 
                technologyRef, 
                waitlistRef, 
                configInView, 
                technologyInView, 
                section4InView, 
                section5InView, 
                waitlistInView,
                toggleCompatibilityVisibility}) => {

    return (
        <header>           

            <button id='instagram-button'
                onClick={() => {
                    window.open('https://www.instagram.com/laifusavingwaifu/');
                }}>                       
                <FontAwesomeIcon icon={faInstagram} style={{color: "inherit"}} />      
            </button>

            <button id='configurator-button' 
                className={configInView ? 'active' : ''}
                onClick={() => {                    
                    configRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                }}>
                    Configurator
            </button>
            <button id='technology-button' 
                className={technologyInView || section4InView || section5InView ? 'active' : ''}
                onClick={() => {
                    technologyRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                }}>                    
                    Technology
            </button>
    
                  
            <img className='main-logo' src={logo} alt="Laifu Saving Waifu" />        

            <button id='waitlist-button'
                onClick={() => {
                    waitlistRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                }}>                             
                Join waitlist <FontAwesomeIcon icon={solid("arrow-right")} style={{color: "inherit"}} />
            </button>

            <button id='compatibility-button'
                onClick={() => {
                    toggleCompatibilityVisibility();
                }}>                             
                Compatibility check <FontAwesomeIcon icon={solid("arrow-right")} style={{color: "inherit",}} />
            </button> 
  
           

        </header>
    )
}

export default Header