import React, { useEffect, useState } from 'react'
import '../styles/section-7.scss';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

import fetchWithTimeout from '../utils/fetchWithTimeout';


gsap.registerPlugin(ScrollTrigger);

const Section7 = ({ inViewRef, scrollRef, setSectionLoaded }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [emailConfirm, setEmailConfirm] = useState(null);

    const submit = async (values) => {
        setIsSubmitting(true);
        setEmailError(null);
        setEmailConfirm(null);

        const response = await fetchWithTimeout(`/api/submit-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: values.emailAddress})
        });
       
        if(response.status !== 200) {
            setIsSubmitting(false);
            setEmailError('Something went wrong, please try again')
            throw new Error(`Server responded with ${response.status}`);
        } else {
            setEmailConfirm(`Thanks. We'll be in touch`)
        }
    
        setIsSubmitting(false);
    };
    
    const EmailSchema = Yup.object().shape({
        emailAddress: Yup.string().email('Invalid email').required('Email is required')                     
    });
    
    useEffect(() => {
        setSectionLoaded(true);
    });

    return (
        <section id='section-7' ref={inViewRef}>
            <div className='left-container' ref={scrollRef}>
                <div id='text-5'>
                    <div className='top-bar'></div>
                    <p className='title'>Register your interest and join the waitlist</p>
                    <p className='text'>If a reserved slot becomes available, you will be contacted</p>

                    <Formik
                        initialValues={{ 
                            emailAddress: '',
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={EmailSchema}
                        onSubmit={(values) => submit(values)}>

                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            errors,
                        }) => (    
                            <form onSubmit={handleSubmit}>

                                { emailError && 
                                    <p className='error-message'>{emailError}</p>
                                }


                                { errors.emailAddress && 
                                    <p className='error-message'>{errors.emailAddress}</p>
                                }                                

                                { emailConfirm && 
                                    <p className='confirm-message'>{emailConfirm}</p>
                                }                                

                                <div className='form-input'>
                                    
                                    <input 
                                        type="text" 
                                        name="emailAddress"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.emailAddress} />


                                    <button disabled={isSubmitting} type="submit">Join waitlist</button>
                                </div>
                            </form>
                        )}

                    </Formik>             

                    <p className='disclaimer-bottom'>Cost subject to make and model of vehicle. Please join the waitlist.</p>       
                </div>   
            </div>
            
            <div className='footer'>
                <a href='/privacy'>Privacy Policy</a>
            </div>
        </section>
    )
}

export default Section7